
import { defineComponent } from "vue";
import { WalletMultiButton, useWallet } from "@/plugins/solana-wallets-vue";

export default defineComponent({
  name: "RegistrationPhantomSection",
  data() {
    return {
      auth: useWallet(),
      data: {},
      publicKey: '',
    };
  },
  components: {
    WalletMultiButton,
  },
  watch: {
    auth: {
      deep: true,
      handler() {
        const data = useWallet();
        if (data.wallet.value != null) {
          if (data.wallet.value.publicKey != null) {
            const { publicKey } = useWallet();
            const token = publicKey.value?.toBase58();
            this.publicKey = publicKey.value?.toBase58();
            localStorage.setItem("phantom-token", token);
            localStorage.setItem("user", JSON.stringify("user-token"));
            localStorage.setItem("sign-in", JSON.stringify("sign-in"));
            this.checkExistence()
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    checkExistence() {
      this.$store.dispatch("auth/checkExistence", this.publicKey).then((res) => {
        if(res.exists) {
          this.$router.push(`/dashboard/mint`);
          this.handleLogin()
        } else this.$router.push(`/create-user`);
      });
    },
    handleLogin() {
      this.$store.dispatch("auth/loginUser", {
        public_key: this.publicKey
      }).then(
        (res) => {
          localStorage.setItem("user", JSON.stringify(res.access_token));
        },
        (error) => {
          this.loading = false;
          this.message = error.response.data.errors;
          this.messageAll = error.response.data;
        }
      );
    },
  },
});
